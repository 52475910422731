<template>
    <div class="righttop" ref="charts" style="width: 100%; height: 100%;"></div>

</template>

<script>
    const echarts = require('echarts/lib/echarts');
    require('echarts/lib/component/toolbox');
    require('echarts/lib/component/tooltip');
    require('echarts/lib/component/grid');
    require('echarts/lib/component/legend');
    require('echarts/lib/chart/bar');
    require('echarts/lib/chart/bar');
    require('echarts/lib/component/dataZoom')
    export default {
        name: "PillarsView",
        props:{
            series:{
                type:Array,
            },
          project:{
              type:Array,
            }
        },
        mounted () {
            this.mycharts()
        },
        watch:{
            series(){
                this.mycharts()
            }
        },
      methods: {
        mycharts(){
          let that=this;
          let myChart =echarts.init(this.$refs.charts,"macarons");
          myChart.off('click')
          myChart.setOption({
                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow',
                    crossStyle: {
                      color: '#999'
                    }
                  },
                },
                legend: {left :'0%'},
                dataZoom: [{
              type: 'slider',
              show: true,
              startValue: 0,
              endValue: 5,
              height: '15',
              bottom: '3%',
              zoomLock: true
            }],
                xAxis:{ //x轴
                  type: 'category',
                  // boundaryGap: false,
                  data: this.project,
                  axisTick:{
                    show :false,
                  },
                  axisLabel: {
                    interval:0,
                    rotate:40
                  }
                },
                yAxis:{
                  type: 'value',
                  axisLabel: { //x轴的坐标文字
                    show: true,
                  },
                  axisTick:{
                    show :false,
                  },
                },
                series: this.series
              })
          myChart.on('click', function (params) {
            that.$emit('histogramDetailed', params.dataIndex);
          })
        }
      }
    }
</script>

<style scoped>

</style>